<template>
    <div>
		<b-modal ref="modalPrintInfosTiers" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("horse.print_infos_horse") }}
				</template>
			</template>

			<div class="col-12">
				<div v-if="form_message !== ''" class="col-12 alert alert-danger">{{ form_message }}</div>
				<div class="form-group">
					<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
					<e-select
						v-model="template"
						id="model_id"
						track-by="model_label"
						label="model_label"
						:placeholder="labelTitleTemplate"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="templates"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group" v-if="template">
					<label for="formats" class="col-form-label">{{ $t("horse.format") }} *</label>
					<e-select
						v-model="format"
						id="format"
						label="name"
						:placeholder="labelTitleFormat"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						:options="formats"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group">
					<b-checkbox v-model="period">{{ $t('tiers.restrict_period') }}</b-checkbox>
				</div>

				<div class="form-group" v-if="period">
					<label class="col-form-label">{{ $t("global.date_debut") }} *</label>
					<e-datepicker v-model="start_date" mode="date"></e-datepicker>

					<label class="col-form-label">{{ $t("global.date_fin") }} *</label>
					<e-datepicker v-model="end_date" mode="date"></e-datepicker>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm" :disabled="isDisabled">{{ $t("global.print") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Common from '@/assets/js/common.js'
import Tiers from '@/mixins/Tiers.js'
import Model from "@/mixins/Model.js"

export default {
	name: "ModalPrintInfosTiers",
	mixins: [Model, Tiers],
	data () {
		return {
			templates: [],
			formats: [],
			template: null,
			format: null,
			tiers_ids: null,
			processing: false,
			period: false,
			start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
			end_date: new Date(),
			form_message: '',
			isDisabled: false,

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleTemplate: this.getTrad("horse.search_doc"),
			labelTitleFormat: this.getTrad("horse.search_format")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.template = null

			let tiers_templates = []
			let tiers_entete_templates = []
			let tiers_formatted_templates = []

			tiers_templates = await this.loadAllModel(0, ['courrier_tiers_envoie'], 'courrier_tiers_envoie')
			tiers_entete_templates = await this.loadAllModel(0, ['courrier_tiers_envoie_entete'], 'courrier_tiers_envoie_entete')
			tiers_formatted_templates = await this.loadAllModel(0, ['courrier_tiers_formatted'], 'courrier_tiers_formatted')
			this.templates = [...tiers_templates, ...tiers_entete_templates, ...tiers_formatted_templates]
		},
		openModal(tiers_ids, nb_selected = null) {
			this.isDisabled = false
			this.form_message = ''
			this.tiers_ids = tiers_ids
			if(nb_selected && nb_selected > 200) {
				this.isDisabled = true
				this.form_message = this.getTrad("global.error_nb_selected")
			}
			this.$refs.modalPrintInfosTiers.show()
		},
		async checkForm() {
			if(this.template && this.format && this.format.id != 6) {
				if(this.period && this.start_date && this.end_date) {
					this.processing = true
					this.printInfosTiers(this.tiers_ids, this.template.model_id, this.format.id, this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
					.then(() => {	
						this.processing = false
					})
				}
				else if(!this.period) {
					this.processing = true
					this.printInfosTiers(this.tiers_ids, this.template.model_id, this.format.id, null, null)
						.then(() => {	
							this.processing = false
						})
				}
				else {
					this.failureToast('toast.no_dates')
				}
			}
			else if(this.template && this.format) {
				this.processing = true

				if((!this.start_date || !this.end_date) && this.period) {
					this.failureToast('toast.no_dates')
					this.processing = false
					return
				}

				let model_ids = []
				let model_label = ""
				let lang = 'fr'
				await Common.asyncForEach(this.tiers_ids, async (tier_id) => {
					const tiers_info = await this.loadTier(tier_id)
					const model = await this.createModel(tier_id, this.template.type.modeltype_id, this.template.model_label, this.template.type.modeltype_template)
					await this.cloneModel(this.template.model_id, model.model_id)
					if(this.period && this.start_date && this.end_date) {
						await this.generateCourrier(model.model_id, this.template.type.modeltype_template, tier_id, this.start_date.toDateInputValue(), this.end_date.toDateInputValue(), tiers_info.tiers_lang)
					}
					else if(!this.period) {
						await this.generateCourrier(model.model_id, this.template.type.modeltype_template, tier_id, null, null, tiers_info.tiers_lang)
					}
					model_ids.push(model.model_id)
					model_label = this.template.model_label
					lang = tiers_info.tiers_lang
				})

				// 	await Common.asyncForEach(model_ids, async (model_id) => {
				// 		await this.printCourrier(model_id, model_label)
				// })

				await this.printCourrier(model_ids, model_label, false, lang)

				this.processing = false
			}
			else {
				this.failureToast('toast.all_inputs')
			}
		},
		async loadFormats(template) {
			if(template && template.type) {
				if(template.type.modeltype_template == 'courrier_tiers_envoie') {
					this.formats = this.getAllFormats()
				}
				else {
					this.formats = this.getAllFormats().filter(f => f.name == 'A4' || f.name == this.getTrad('horse.a4saut'))
					this.format = this.formats.find(f => f.name == 'A4')
				}
			}
		}
	},
	watch: {
		async template() {
			await this.loadFormats(this.template)
		}
	}
}
</script>